<template>
  <div v-if="metrics">
    <div class="d-flex flex-row flex-grow-1 flex-wrap align-items-top metrics-bar text-dark">
      <metrics-settings-panel-item v-for="(metric, index) of metricsItems"
                                   :key="index"
                                   :title="metric.title"
                                   :metric="metric.metric"
                                   :isFirst="index === 0"
                                   class="pt-4 px-4 mr-5"
                                   :item="item"
                                   :lastBudgetItem="lastBudgetItem === metric.title"
                                   :tooltip="tooltips[metric.title]">

        <template #title v-if="metric.title === labels.appliedBudget">
          <span v-if="metrics.budget && metrics.budget !== '--'"
                class="small mt-2"
                :class="{ 'text-danger': isBudgetAmountDanger(item) }">
              <span class="text-secondary title-metric">{{labels.appliedBudget}}</span>
            </span>
        </template>

        <template v-if="metric.title === 'Spend So Far'">
          <img v-if="budgetOptimizerStatusDTO && budgetOptimizerStatusDTO.spendSoFar && budgetOptimizerStatusDTO.spendSoFar.length > 0"
               :src="renderSparkLine(metrics.budgetOptimizerStatusDTO.spendSoFar, metrics.pacing)"
               height="22"
               width="140">
        </template>

        <template v-else-if="metric.title === 'Account Budget' && item.accountBudgetAdjustment">
          <span class="white-space-no-wrap line-height-1">
            <span class="text-125">{{metric.metric}}</span>
            <span class="small font-weight-bold cursor-pointer ml-2"
                  :class="item.accountBudgetAdjustment > 0 ? 'text-success' : 'text-info'"
                  v-p-tooltip.top="'Temporary Budget Adjustment'">
              {{item.accountBudgetAdjustment > 0 ? '+' : ''}}{{ $filters.currency(item.accountBudgetAdjustment, 2) }}
            </span>
          </span>
        </template>

        <template v-else-if="metric.title === labels.appliedBudget">
          <span class="white-space-no-wrap line-height-1"
                  :class="{ 'text-danger': isBudgetAmountDanger(item) }">
            <span class="text-125" style="font-weight: 500;">{{$filters.currency(metrics.budget, 2)}}</span>
            <span v-if="nonBudgetedSpend"
                  class="small font-weight-bold cursor-point ml-2 text-success">+{{$filters.currency(nonBudgetedSpend, 2)}}</span>
          </span>
        </template>

        <template v-else> <!-- handling for case here where the default slot does not get picked up - SNG -->
          <span class="text-125 white-space-no-wrap" style="line-height: 1; font-weight: 500;">{{(metric.metric || '--')}}</span>
        </template>

      </metrics-settings-panel-item>

      <div class="metrics-bar-customizer d-flex align-items-center text-center mr-4 ml-auto" @click="clickMetricsBarCustomizer($event)">
        <action-icon v-if="!showPreview"
                     icon="edit"
                     buttonText="Edit"
                     icon-size="sm"
                     class="px-4"
                     variant="link-info"
                     v-p-tooltip.left="'Customize Metrics Bar'" />
      </div>
      <p-popover ref="metricsSettingsPopoverRef">
        <div class="d-flex border-bottom pb-3 mb-4">
          <span class="flex-grow-1">Available Metrics Fields</span>
          <t-button-inline severity="secondary"
                    class="p-0"
                    @click="$refs.metricsSettingsPopoverRef.hide()">
            <fluency-icon type="x" />
          </t-button-inline>
        </div>

        <div v-if="metricsBarItems" class="metrics-bar-popover-content">

          <p class="fancy-separator my-4">Selected</p>
          <template v-for="(m, i) in metricsBarItems"  :key="`set-${i}`">
            <div v-if="metricsBarItems.indexOf(m) > -1"
                 class="inline-edit cursor-pointer py-1 px-2 draggable hover-highlight"
                 :ref="`draggable${i}`"
                 @dragover.prevent="dragOver($event, i)"
                 @dragenter="dragEnter($event, i)"
                 @dragleave="dragLeave($event, i)"
                 @drop.prevent="drop($event, i)">
              <div class="drag-inside m-0 d-flex align-items-center"
                   :draggable="true"
                   @dragstart="dragStart($event, i)"
                   @dragend="dragEnd($event, i)">
                <div class="position-relative flex-grow-0 h-100"
                     @click="setMetricsBarItem(m)">
                  <fluency-icon type="x" class="in-active-set hover-icon" />
                  <fluency-icon type="checkmark" class="in-active-set hover-icon opposite" />
                </div>
                <span class="item flex-grow-1">{{getMetricLabel(m)}}</span>
                <fluency-icon type="move" class="fluency-gray" />
              </div>
            </div>
          </template>

          <div class="text-right">
            <t-button variant="link" size="sm" @click="clearMetricsBar">Restore Default Set</t-button>
          </div>

          <p class="fancy-separator my-4">Available</p>
          <template v-for="(m, i) in availableMetricsFields" :key="`unset-${i}`">
            <div v-if="metricsBarItems.indexOf(m.value) === -1"
                 class="inline-edit my-2 px-2">
              <a href="#" class="item" @click.prevent="setMetricsBarItem(m.value)">
                <fluency-icon type="add" class="hover-icon" />
                {{m.label}}
              </a>
            </div>
          </template>

        </div>
      </p-popover>
    </div>
  </div>
</template>

<script>
import MetricsSettingsPanelItem from './metricsSettingsPanelItem'
import ActionIcon from '@/components/pages/manage/actionIcon'
import { DefaultPreferences } from '~/assets/js/constants'
import { renderSparkLine } from '../../../assets/js/utilities'

export default {
  name: 'MetricsSettingsPanel',
  props: {
    defaultItem: {
      type: String,
      default: 'activeItem'
    },
    showPreview: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ActionIcon,
    MetricsSettingsPanelItem
  },
  data () {
    return {
      accountBudgets: [],
      tooltips: {},
      filters: this.$filters,
      nonBudgetedSpend: 0,
      labels: {
        appliedBudget: 'Total Applied Budget'
      }
    }
  },
  watch: {
    item: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.getBudgetBreakdown(newVal)
        } else {
          this.tooltips = {}
        }
      }
    }
  },
  computed: {
    metricsBarItems () {
      return (this.$store.getters.metricsBarItems || []).filter(metric => this.availableMetricsFields.some(field => field.value === metric))
    },
    lastBudgetItem () {
      let title = null

      if (this.metricsItems.length) {
        this.metricsItems.forEach(n => {
          if (n.title.indexOf('Budget') > -1) {
            title = n.title
          }
        })
      }

      return title
    },
    item () {
      return this.$store.getters[this.defaultItem]
    },
    metrics () {
      const m = this.item
      if (m && m.hasOwnProperty('metricsDTO')) {
        return {
          ...m,
          ...m.metricsDTO
        }
      } else {
        return null
      }
    },
    budgetOptimizerStatusDTO () {
      return this.item?.budgetOptimizerStatusDTO || null
    },
    metricsItems () {
      const metrics = this.metrics || {}
      const { currency } = this.filters
      const visibleItemsArr = [
        this.metricObj('Account Budget', metrics.approvedAccountBudget >= 0 ? currency(metrics.approvedAccountBudget, 2) : null, true),
        metrics.planType !== 'budget' ? this.metricObj(this.labels.appliedBudget, currency(metrics.budget, 2)) : null,
        metrics.planType === 'budget' && !metrics.lifetimeBudget ? this.metricObj('Budget', currency(metrics.budget, 0, true)) : null,
        this.metricObj('Flighted Budget', metrics.lifetimeBudget > 0 ? currency(metrics.lifetimeBudget, 2) : null, true)
      ]

      const itemsToShow = this.showPreview ? DefaultPreferences.metricsBarItemsCondensed : this.metricsBarItems

      if (itemsToShow?.length) {
        itemsToShow.forEach(m => {
          if (this.availableMetricsFields.some(field => field.value === m)) {
            visibleItemsArr.push(this.buildMetric(m))
          }
        })
      }

      return visibleItemsArr.filter(metric => !!metric)
    },
    availableMetricsFields () {
      const metricSkeleton = this.$store.getters.skeletons?.metrics
      const arr = []
      const endingIdentifier = 'Status'

      if (metricSkeleton) {
        Object.keys(metricSkeleton).forEach(metric => {
          let m = metric

          if (metric.endsWith(endingIdentifier)) {
            const n = metric.lastIndexOf(endingIdentifier)

            m = m.slice(0, n) + m.slice(n).replace(endingIdentifier, '')
            let label = this.$filters.convertFromCamelCase(m)
            if (this.approvedBudgetPacing && m === 'pacing') {
              label = 'Pacing (to Applied)'
            }
            arr.push({ value: m, label })
          }
        })
      }

      if (this.approvedBudgetPacing && !arr.some(item => item.value === 'approvedBudgetPacing')) {
        arr.push({ value: 'approvedBudgetPacing', label: 'Pacing (to Approved)' })
      }

      arr.sort((a, b) => (a.label > b.label) ? 1 : -1)

      return arr
    },
    partners () {
      if (this.$store.getters.partners) {
        return this.$store.getters.partners.toMap(p => p.partnerId)
      }
      return {}
    },
    approvedBudgetPacing () {
      return this.$store.getters.user?.capabilities?.ApprovedBudgetPacing
    }
  },
  methods: {
    clickMetricsBarCustomizer ($event) {
      this.trackMetricsActionEvents($event)
      this.$refs.metricsSettingsPopoverRef.show($event)
    },
    dragStart: function (e, fieldIndex) {
      e.dataTransfer.setData('_fieldIndex', fieldIndex)

      this.addClass(fieldIndex, 'dragging')
    },
    dragEnd: function (e, fieldIndex) {
      this.removeClass(fieldIndex, 'dragging')
    },
    dragEnter: function (e, fieldIndex) {
      this.addClass(fieldIndex, 'dragging-over')
    },
    dragLeave: function (e, fieldIndex) {
      this.removeClass(fieldIndex, 'dragging-over')
    },
    dragOver: function (e, fieldIndex) {
      this.addClass(fieldIndex, 'dragging-over')
    },
    drop: function (e, fieldIndex) {
      const droppedIndex = parseInt(e.dataTransfer.getData('_fieldIndex'))

      this.removeClass(fieldIndex, 'dragging-over')
      this.removeClass(fieldIndex, 'dragging')

      const arrCopy = [...this.metricsBarItems]

      arrCopy.move(droppedIndex, fieldIndex)

      this.reorderMetricsBar(arrCopy)
    },
    addClass (fieldIndex, className) {
      this.$refs[`draggable${fieldIndex}`][0].classList.add(className)
    },
    removeClass (fieldIndex, className) {
      this.$refs[`draggable${fieldIndex}`][0].classList.remove(className)
    },
    buildMetric (key) {
      const metrics = this.metrics || {}
      const { currency, percentage, number } = this.filters

      switch (key) {
        case 'pacing':
          if (this.approvedBudgetPacing) {
            return this.metricObj('Pacing (to Applied)', percentage(metrics.pacing))
          }
          return this.metricObj('Pacing', percentage(metrics.pacing))
        case 'approvedBudgetPacing':
          return this.metricObj('Pacing (to Approved)', percentage(metrics.approvedBudgetPacing))
        case 'clicks':
          return this.metricObj('Clicks', number(metrics.clicks, 0, true))
        case 'clickThroughRate':
          return this.metricObj('CTR', percentage(metrics.CTR || metrics.clickThroughRate))
        case 'costPerClick':
          return this.metricObj('CPC', currency(metrics.CPC || metrics.costPerClick, 2))
        case 'impressions':
          return this.metricObj('Impressions', number(metrics.impressions, 0, true))
        case 'conversions':
          return this.metricObj('Conversions', number(metrics.conversions, 0, true))
        case 'costPerThousandImpressions':
          return this.metricObj('CPM', currency(metrics.CPM || metrics.costPerThousandImpressions, 2))
        case 'conversionRate':
          return this.metricObj('Conversion Rate', percentage(metrics.Conv || this.metrics?.conversionRate))
        case 'cost':
          return this.metricObj('Cost', currency(metrics.cost, 2))
        case 'impressionShare':
          return this.metricObj('Imp. Share', percentage(metrics.impressionShare))
        default:
          return this.metricObj(this.$filters.convertFromCamelCase(key), metrics[key])
      }
    },
    clearMetricsBar () {
      this.$store.dispatch('clearMetricsBarItems')
    },
    setMetricsBarItem (key) {
      this.$store.dispatch('setMetricsBarItem', key)
    },
    reorderMetricsBar (arr) {
      this.$store.dispatch('setMetricsBarFull', { payload: arr })
    },
    metricObj (title, metric, returnNullIfNoMetric = false) {
      if (returnNullIfNoMetric && ((!metric && metric !== 0) || metric === 'NaN%')) {
        return null
      }

      return { title, metric }
    },
    renderSparkLine,
    async getBudgetBreakdown (item) {
      if (this.metrics?.planType === 'account' && item?.accountPlanId && item?.children) {
        this.accountBudgets = item.children
        let appliedBudgetTooltip = ''
        let cost = ''
        let nonBudgetedSpend = 0
        item.children.forEach(subAccount => {
          const partner = this.partners[subAccount.partnerId]?.name
          if (partner) {
            appliedBudgetTooltip += `${partner}: ${this.$filters.currency(subAccount?.metricsDTO?.budget, 2)}${subAccount?.metricsDTO?.nonBudgetedMonthlySpend ? ' (+' + this.$filters.currency(subAccount?.metricsDTO?.nonBudgetedMonthlySpend) + ')' : ''}\n`
            cost += `${partner}: $${Math.round(subAccount?.metricsDTO?.cost)}\n`
          }
          if (subAccount?.metricsDTO?.nonBudgetedMonthlySpend) {
            nonBudgetedSpend += subAccount.metricsDTO.nonBudgetedMonthlySpend
          }
        })
        this.nonBudgetedSpend = nonBudgetedSpend
        this.tooltips[this.labels.appliedBudget] = appliedBudgetTooltip
        this.tooltips.Cost = cost
      }
    },
    getMetricLabel (value) {
      return this.availableMetricsFields.find(field => field.value === value)?.label || ''
    },
    trackMetricsActionEvents (event) {
      const item = event.target.closest('.btn-action')
      if (!item) return

      this.$track.event('metrics action', {
        metricsAction: item.innerText?.trim(),
        drawerOpen: this.$route?.query?.hasOwnProperty('settingsItemId'),
        settingsItemType: this.$route?.query?.settingsItemType
      }, 'manage', item)
    },
    isBudgetAmountDanger (item) {
      return item?.accountBudgetAligned === false

      // return this.metrics.approvedAccountBudget && Math.round(this.metrics.approvedAccountBudget + (item.accountBudgetAdjustment || 0)) !== Math.round(this.metrics.budget)
    }
  }
}
</script>

<style lang='scss' scoped>
  .draggable {
    cursor: grab;
    border-top: 2px solid white;

    * { cursor: grab; }
    .iconic { visibility: hidden; }
    &:hover .iconic { visibility: visible; }
    &:active { cursor: grabbing; }
  }

  .metrics-bar-popover-content {
    max-height: 70vh;
    overflow-y: scroll;

    .inline-edit {
      position: relative;

      .item {
        margin-left: 15px;
      }

      .hover-icon {
        position: absolute;
        top: 2px;
        left: 2px;

        &.in-active-set {
          top: -6px;
          left: 0;
        }
      }

      &:hover .hover-icon.opposite,
      .hover-icon {
        visibility: hidden;
      }
      .hover-icon.opposite,
      &:hover .hover-icon {
        visibility: visible;
      }
    }
  }

  .dragging-over {
    border-top: 2px solid #ccc;
  }
</style>
