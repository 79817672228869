<template>
  <div class="header-icon-notifications d-flex align-items-center position-relative px-4 text-decoration-none notification-btn btn-link-info"
       :class="{ 'dropdown-toggle': caret }"
       role="button"
       @click="$emit('click')">
    <fluency-icon :type="iconType" class="header-dropdown-icon" :size="size" />
    <span v-if="warning" class="badge badge-pill badge-warning header-nav-notification">
      <fluency-icon :type="warningIcon" class="text-dark" />
    </span>
    <span v-else-if="notifications > 0" class="badge badge-pill badge-info header-nav-notification" :class="notificationsClass">{{notifications}}</span>
    <span v-if="showInternalNewBuildAvailable" class="red-dot"></span>
  </div>
</template>

<script>
export default {
  name: 'headerIconNotifications',
  props: {
    title: {
      type: String,
      default: ''
    },
    warning: {
      type: Boolean,
      default: false
    },
    warningIcon: {
      type: String,
      default: 'warning'
    },
    notifications: {
      type: Number,
      default: 0
    },
    iconType: {
      type: String,
      required: true
    },
    notificationsClass: {
      type: [Array, Object]
    },
    size: {
      type: String,
      default: 'md'
    },
    caret: {
      type: Boolean,
      default: false
    },
    showInternalNewBuildAvailable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-btn {
  box-shadow: none !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.header-nav-notification {
  position: absolute;
  top: 10px;
  left: 0;
}

.dropdown-toggle {
  padding-right: 5px !important;
}

.red-dot {
  position: absolute;
  top: 12px;
  right: 12px;
  height: 6px;
  width: 6px;
  border-radius: 3px;
  background-color: var(--p-danger-color);
  color: var(--p-danger-color);
}
</style>
<style lang="scss">
.header-icon-notifications {
  .fluency-user-circle {
    top: -1px;
  }
  .fluency-help-chat {
    width: 18px;
  }
}
</style>
